<script setup lang="ts">
const props = defineProps({
  isModalShow: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "Are you sure?",
  },
  id: {
    type: String,
    default: "confirm-modal",
  },
  status: {
    type: String,
    default: "default",
  },
});
const isModalShow = toRef(props, "isModalShow");
const label = toRef(props, "label");
const status = toRef(props, "status");

const emit = defineEmits(["closeModal", "confirmModal"]);

const hideModal = () => {
  emit("closeModal");
};
const confirmModal = () => {
  emit("confirmModal");
};
</script>
<template>
  <div
    class="fixed inset-0 z-40 bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
    :class="isModalShow ? 'flex' : 'hidden'"
  />
  <div
    :id="id"
    tabindex="-1"
    class="fixed left-0 right-0 top-0 z-50 h-[calc(100%-1rem)] max-h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden md:inset-0"
    :class="isModalShow ? 'flex' : 'hidden'"
  >
    <div class="relative max-h-full w-full max-w-md p-4">
      <div class="relative rounded-lg bg-white shadow dark:bg-gray-700">
        <button
          type="button"
          class="absolute end-2.5 top-3 ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
          @click="hideModal"
        >
          <IconsX class="h-3 w-3" />
          <span class="sr-only">Close modal</span>
        </button>
        <div class="p-4 text-center md:p-8">
          <IconsExclamation class="mx-auto mb-4 mt-6 h-12 w-12 text-primary" />
          <!-- eslint-disable vue/no-v-html -->
          <h3
            class="mb-5 font-normal text-gray-500 dark:text-gray-400"
            v-html="label"
          />
          <!--eslint-enable-->
          <button
            :id="`submit-${id}`"
            type="button"
            class="flik-button me-2"
            :class="status === 'danger' ? 'bg-red-700 text-white' : ''"
            @click="confirmModal()"
          >
            Ya, saya yakin
          </button>
          <button
            :id="`cancel-${id}`"
            type="button"
            class="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600"
            @click="hideModal()"
          >
            Tidak, batalkan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
